<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Data Terhimpun</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_kajian
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Dari {{ count_guru }} Guru</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Hadir</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_hadir
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Sakit/Izin/Alfa</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{ count_sakit }} / {{ count_izin }} /
          {{ count_alfa }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Tidak Berjalan</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_tidak_berjalan
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
    </div>

    <div class="full-width">
      <q-card flat bordered class="q-mt-md">
        <q-card-section class="row justify-between items-end">
          <div v-if="range_date.from" class="text-grey">
            Periode :{{ range_date.from | moment("DD MMMM YYYY") }} s.d
            {{ range_date.to | moment("DD MMMM YYYY") }}
          </div>
          <div v-else class="text-grey text-italic">-- s.d. --</div>
          <div class="row q-gutter-md items-end">
            <q-select
              filled
              label="Filter by Divisi"
              dense
              map-options
              v-model="seldiv"
              use-input
              hide-selected
              fill-input
              input-debounce="0"
              :options="filtereddiv"
              style="max-width:150px"
              @input="fetchData('')"
              @filter="filterFn"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">No results</q-item-section>
                </q-item>
              </template>
            </q-select>
            <q-input
              filled
              v-model="search"
              placeholder="Cari Nama"
              dense
              class="bg-white"
              style="border-radius: 5px; width: 350px;"
              @keypress.enter="fetchData('nama')"
            >
              <template v-slot:append>
                <q-btn
                  flat
                  icon="search"
                  class="text-white absolute-right"
                  @click="fetchData('nama')"
                  style="background-color:#03913F"
                />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr class="text-left">
              <th>No Induk</th>
              <th>Nama</th>
              <th>Gender</th>
              <th>Divisi</th>
              <th>Status Kehadiran</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class>
            <tr v-for="d in listData" :key="d.spp_id">
              <td>{{ d.no_induk }}</td>
              <td>{{ d.nama }}</td>
              <td>{{ d.jenis_kelamin }}</td>
              <td>{{ d.divisi }}</td>
              <td>{{ d.status_kehadiran }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">tidak ada data</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-pagination
            v-model="activePage"
            :max="Math.ceil(count_page / 10)"
            input
            input-class="text-orange-10"
            @click="fetchData(orderBy)"
          />
        </q-card-actions>
      </q-card>
    </div>
  </q-page>
</template>

<style></style>

<script>
export default {
  name: "Result",
  data() {
    return {
      seldiv: "Semua Divisi",
      filtereddiv: [],
      kajianActive: "Terlaksana",
      listData: [],
      need_karantina: 0,
      need_isolasi: 0,
      count_guru: 0,
      count_hadir: 0,
      count_sakit: 0,
      count_izin: 0,
      count_alfa: 0,
      count_kajian: 0,
      count_page: 0,
      count_tidak_berjalan: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      range_date: {},
      kode_unik: "ARRCVD",
      search: "",
      confirm: false,
      count_karantina: 0
    };
  },
  mounted() {
    this.fetchData("no_induk");
  },
  methods: {
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.filtereddiv = this.$DIVISI.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    fetchData(order) {
      // this.orderBy = order

      // if(this.orderType == 'ASC'){
      //   this.orderType = 'DESC'
      // }
      // else{
      //   this.orderType = 'ASC'
      // }
      this.orderBy = "id";
      this.orderType = "DESC";

      var src = "%25";
      if (this.search != "") src = this.search;

      this.$http
        .get(
          "/list_kajian/" +
            src +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10 +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData = result.data;
          // this.count_kajian = result.data.length
        });
      this.$http
        .get("/count_list_kajian/" + src + "/" + this.seldiv, {})
        .then(result => {
          this.count_page = result.data[0].count;
        });
      this.$http.get("/jml_guru", {}).then(result => {
        this.count_guru = result.data.count;
      });
      this.$http.get("/jml_kajian/" + src, {}).then(result => {
        this.count_kajian = result.data.count;
      });
      this.$http.get("/jml_hadir", {}).then(result => {
        this.count_hadir = result.data.count;
      });
      this.$http.get("/jml_sakit", {}).then(result => {
        this.count_sakit = result.data.count;
      });
      this.$http.get("/jml_izin", {}).then(result => {
        this.count_izin = result.data.count;
      });
      this.$http.get("/jml_alfa", {}).then(result => {
        this.count_alfa = result.data.count;
      });
      this.$http.get("/jml_tidak_berjalan", {}).then(result => {
        this.count_tidak_berjalan = result.data.count;
      });
      this.$http.get("/range_kajian_date", {}).then(result => {
        this.range_date = result.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  // min-width: 250px;
  flex-grow: 2;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #babef2;
}
.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 36px;
}
</style>
